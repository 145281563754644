import { Contract } from "@ethersproject/contracts";

import { UniswapInterfaceMulticall } from "@uniswap/redux-multicall/dist/abi/types";
import UniswapInterfaceMulticallJson from "@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json";

import IDO_ABI from "../abis/IDO.json";
import Pair_ABI from "../abis/Pair.json";
import { useMemo } from "react";
import { IDO, Pair } from "../abis/types";
import { getContract } from "../utils";
import { addresses } from "../configs/constants";
import { chains } from "../configs/wagmiConfig";
import { MULTICALL_ADDRESS } from "../constants/address";
import ethers from "ethers";
import { useWeb3 } from "./useWeb3";
import { useNetwork } from "wagmi";
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId } from "src/constants/chains";
const { abi: MulticallABI } = UniswapInterfaceMulticallJson;

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true,
): T | null {
  const { provider, chainId, account } = useWeb3();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === "string") address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined);
    } catch (error) {
      console.log("[error]:", error);
      console.error("Failed to get contract", error);
      return null;
    }
  }, [addressOrAddressMap, ABI, provider, chainId, withSignerIfPossible, account]) as T;
}

export function useChainId() {
  const { chain } = useNetwork();
  if (chain) {
    if (ALL_SUPPORTED_CHAIN_IDS.indexOf(chain.id) == -1) return chains[0]?.id;
  }
  return chain ? chain?.id : chains[0]?.id;
}

export function useIDOContract() {
  const chainId = useChainId();
  return useContract<IDO>(addresses[chainId]?.IDO, IDO_ABI.abi);
}

export function useWETHContract() {
  const chainId = useChainId();
  return useContract<Pair>(addresses[chainId]?.WETH, Pair_ABI.abi);
}

export function useEDEContract() {
  const chainId = useChainId();
  return useContract<Pair>(addresses[chainId]?.EDE, Pair_ABI.abi);
}

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(MULTICALL_ADDRESS, MulticallABI) as UniswapInterfaceMulticall;
}
