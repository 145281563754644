interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  84531: {
    IDO: "0xe73760911Ed3E48349B41D341fDdc1AF2AC7FAfa",
    EDE: "0x14580128a87f84772Fe9CC4e48c19Fc8811f0934",
    WETH: "0x93bF5e614bd74214133ED7cA2e75dBa362611960",
  },
  8453: {
    IDO: "0x57b78bff7297635414C55443fF4E3dae8A55a74a",
    EDE: "0x0A074378461FB7ed3300eA638c6Cc38246db4434",
    WETH: "0x4200000000000000000000000000000000000006",
  },
};
