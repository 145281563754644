import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";

import "./header.scss";
import Logo from "../../assets/images/logo.svg";
import LogoM from "../../assets/images/logo-m.svg";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export default function Header(props) {
  const isSmallScreen = useMediaQuery("(max-width: 1080px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");

  const ConnectButtonBox = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 32px 8px 20px !important;
    color: #9b96ff;
    background: transparent;
    font-size: 12px;
    font-weight: 500;
    font-family: "Poppins-Medium";
    border-radius: 9px;
    border: 1px solid #9b96ff;
    transition: 200ms ease;
    &:hover {
    }
    &:active {
    }
  `;

  return (
    <div className="header">
      <Box display="flex" justifyContent="center" style={{ height: "100%" }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" className="containerBox">
          <img src={isSmallScreen ? LogoM : Logo} alt="Logo" className="" />
          <div className="">
            <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                  ready && account && chain && (!authenticationStatus || authenticationStatus === "authenticated");

                return (
                  <div
                    {...(!ready && {
                      "aria-hidden": true,
                      style: {
                        opacity: 0,
                        pointerEvents: "none",
                        userSelect: "none",
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return <ConnectButtonBox onClick={openConnectModal}>CONNECT WALLET</ConnectButtonBox>;
                      }

                      if (chain.unsupported) {
                        return <ConnectButtonBox onClick={openChainModal}>Wrong network</ConnectButtonBox>;
                      }

                      return (
                        <div style={{ display: "flex", gap: 12 }}>
                          {!isVerySmallScreen && (
                            <ConnectButtonBox
                              onClick={openChainModal}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {chain.hasIcon && (
                                <div
                                  style={{
                                    background: chain.iconBackground,
                                    width: 24,
                                    height: 24,
                                    borderRadius: 999,
                                    overflow: "hidden",
                                    marginRight: 4,
                                  }}
                                >
                                  {chain.iconUrl && (
                                    <img
                                      alt={chain.name ?? "Chain icon"}
                                      src={chain.iconUrl}
                                      style={{ width: 24, height: 24 }}
                                    />
                                  )}
                                </div>
                              )}
                              <div className="flex-1">{chain.name}</div>
                            </ConnectButtonBox>
                          )}

                          <ConnectButtonBox
                            onClick={openAccountModal}
                            type="button"
                            style={{ whiteSpace: "nowrap", padding: "0 24px" }}
                          >
                            {account.displayName}
                            {account.displayBalance ? ` (${account.displayBalance})` : ""}
                          </ConnectButtonBox>
                        </div>
                      );
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom>
          </div>
        </Box>
      </Box>
    </div>
  );
}
