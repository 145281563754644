import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { lazy, Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import useTheme from "./hooks/useTheme";
import { light as lightTheme } from "./themes/light.js";
import AOS from "aos";
import "aos/dist/aos.css";

import { Box } from "@mui/material";
import Header from "./components/Header";
import LoadingSplash from "./components/LoadingSplash";
import { cssTransition, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";
import "./assets/css/toast.scss";
import "./assets/css/common.scss";

import "@rainbow-me/rainbowkit/styles.css";

AOS.init();

const DEBUG = false;
const Home = lazy(() => import("./views/home"));
if (DEBUG) console.log(" Connecting to Mainnet Ethereum");

// const blockExplorer = targetNetwork.blockExplorer;

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  appendPosition: false,
  collapse: true,
  collapseDuration: 200,
  duration: 200,
});

function App() {
  const [theme, mounted] = useTheme();
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  let themeMode = lightTheme;

  const [show, setShow] = useState(false);

  return (
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
      <ToastContainer
        limit={1}
        transition={Zoom}
        position="bottom-right"
        closeButton={false}
        newestOnTop={false}
        pauseOnFocusLoss
        closeOnClick={false}
        draggable={false}
        icon={false}
      />
      <div className="App">
        <Suspense fallback={<LoadingSplash />}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <div className={`containerBox`}>
              <Header />
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </div>
          </Box>
        </Suspense>
      </div>
    </ThemeProvider>
  );
}

export default App;
