import { connectorsForWallets, getDefaultWallets } from '@rainbow-me/rainbowkit';
import {
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  okxWallet,
  safeWallet,
  trustWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createConfig } from 'wagmi';
import { baseGoerli, base } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    // baseGoerli,
    base
  ],
  [publicProvider()]
);

export const projectId = 'd9e02d8e4a4bb8fe574b63430fa1cf34';

const { wallets } = getDefaultWallets({
  appName: 'EDE',
  projectId,
  chains,
});

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: 'Other',
    wallets: [
      okxWallet({
        projectId,
        chains
      })
    ],
  },
]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

// import {
//   w3mConnectors,
//   w3mProvider,
// } from "@web3modal/ethereum";
// import { configureChains, createConfig } from "wagmi";
// import { mainnet, optimism, polygon, zkSync } from "wagmi/chains";

// // 1. Get projectID at https://cloud.walletconnect.com
// export const projectId = "d9e02d8e4a4bb8fe574b63430fa1cf34";

// // 2. Configure wagmi client
// export const chains = [zkSync];

// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
// export const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({ version: 1, chains, projectId }),
//   publicClient,
// });